// import SideMenu from '../components/sideMenu'
import ResetPasswordCard from '../components/login/reset_password'
import '../styles/wallet.scss'

function ResetPassword () {
  return (
    <div>
        <ResetPasswordCard></ResetPasswordCard>
    </div>
  )
}

export default ResetPassword
