import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';
import { message } from 'antd';
import '../../styles/antd-message.scss';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignUpSvg from '../../resources/signup.svg';

message.config({
    top:100,
    duration:2
})

const RetsetPasswordSuccessed = (msg) => {
  message.success(`${msg}`, 3);
};

const ResetPasswordFailed = (msg) => {
  message.error(`${msg}, Account reset password failed.`);
};

const InputError = (msg) => {
    message.error(`${msg}`, 3);
  };
  
const AccountResetActiveCard = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        handleGetResetToken();
    },[]);
    const getPageQuery = () => {
        return window.location.href.split('?')[1]
    }
    const [resetToken, setResetToken] = useState()

    const handleGetResetToken = async()  => {
        let allParams = getPageQuery()
        console.log(allParams)

        if (allParams === undefined) {
            window.history.back(-1)
        }

        let resetKey = allParams.split('=')[0]
        let resetToken = allParams.split('=')[1] // token
        if (resetKey !== 'token') {
            window.history.back(-1)
        }
        setResetToken(resetToken)

        setIsLoading(true)
    }
    const [showPassword, setShowPassword] = useState(false);

    const [password, setPassword] = useState();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleResetPassword = async()  => {
        if (password === '' || password === undefined ) {
            InputError("Password cannot be empty!")
            return
        }

        const data = {
            "token": resetToken,
            "password": password,
        }
        const result = await axios({
            method: "patch",
            url: `/api/forget_password/`,
            headers: {
            'Content-Type': 'application/json',
            },
            data
        });
        console.log(result.data)
        if (result.data.status === 'successed') {
            RetsetPasswordSuccessed(result.data.message)
            navigate("/logout")
        } else {
            ResetPasswordFailed(result.data.message)
        }
    }

    if (isLoading) {
        return(
            <div className="login-card signup-card">
            <img src={SignUpSvg}/>
            <div className='signup-title'>Reset password</div>
            <div className="code-2fa-body-1">Already got your password back? <a className='private-info-link' href="/login">Sign in.</a></div>
            <FormControl sx={{ m: 0, width: '360px', minHeight: '48px', }} variant="outlined" size="small">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            inputProps={{ 'aria-label': 'Without label' }}
            placeholder="Enter your new password"
            className='password-value'
            value={password}
            // onKeyDown={(e) => handleKeyDownSignUp(e)}
            onChange={(e) => handlePasswordChange(e)}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            displayEmpty
          />
        </FormControl>
            <div className="login-btn-base login-btn-background login-btn-choose check-signup-font" onClick={handleResetPassword}>
                {/* <div className={"check-signup-font"}> */}
                    Reset Your Password
                {/* </div> */}
            </div>
        </div>
        )
    } 

}

export default AccountResetActiveCard;
