import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const InputError = (msg) => {
  message.error(`${msg}`, 3);
};

const SignUpCard = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [checked, setChecked] = useState(false);
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.checked;
        
        setChecked(value)
    }

  useEffect(() => {
      handleUserInfo();
  },[]);

  const handleUserInfo = async()  => {
      try {

          const result = await axios.get("/api/userinfo/", {headers: {
              "dorafactory-token": localStorage.getItem('token'),
          },});

          if(result.data['status'] === "successed"){
              navigate("/home")
          }
          setIsLoading(true)
      } catch {
          setIsLoading(true)
          navigate("/signup")
      }
  }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
    }
    const handleKeyDownSignUp = (event) => {
      if (event.keyCode === 13) {
          handleSignUpMessage();
        }
    }

    const handleSignUpMessage = async()  => {
        if (email === '' || password === '' || email === undefined || password === undefined) {
            InputError("Email and password cannot be empty!")
            return
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = regex.test(email);
        
        if (!isValidEmail) {
            InputError("Please enter the correct email address.")
            return
        }

        const data = {
            "email": email,
            "password": password
        };

        const result = await axios(
            {
                method: "post",
                url: '/api/register/',
                headers: {
                'Content-Type': 'application/json'
                },
                data
            });

        if(result.data['status'] === 'successed'){
            localStorage.setItem("token", result.data['token'].toString())
            localStorage.setItem('email', email)
            navigate("/verify_email")
        }
        console.log(result)
    }

    if (isLoading) {
      return(
        <div className="login-card signup-card">
            <img src={SignUpSvg}/>
            <div className='signup-title'>Sign up</div>
            <div className="description">Already have an account? {" "}<a className='private-info-link' href="/login">Sign in.</a></div>

        <FormControl sx={{ m: 0, width: '360px' }} variant="outlined" size="small">
          <OutlinedInput
            id="outlined-adornment-weight"
            className="email-value"
            // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            placeholder="Enter your email address"
            value={email}
            onKeyDown={(e) => handleKeyDownSignUp(e)}
            onChange={(e) => handleEmailChange(e)}
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 0, width: '360px', minHeight: '48px', }} variant="outlined" size="small">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            inputProps={{ 'aria-label': 'Without label' }}
            placeholder="Enter your password"
            className='password-value'
            value={password}
            onKeyDown={(e) => handleKeyDownSignUp(e)}
            onChange={(e) => handlePasswordChange(e)}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            displayEmpty
          />
        </FormControl>

            {/* <div className={checked?"login-btn-base login-btn-background login-btn-choose":"check-signup-btn"} onClick={checked?(() => handleSignMessage()):null} disabled={!checked}> */}
            <div className={"login-btn-base login-btn-background login-btn-choose"} onClick={() => handleSignUpMessage()}>
                <div className={"check-signup-font"}>
                    Create Account
                </div>
            </div>

            <div className="text-center">By signing up, you agree to the Terms of Service.</div>

        </div>
    )
  }
}


export default SignUpCard;