import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const ActiveSuccessed = (msg) => {
  message.success(`${msg}, Account successfully activated.`, 3);
};

const ActiveFailed = (msg) => {
  message.error(`${msg}, Account activation failed.`);
};

const ActiveAccountCard = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        handleActive();
        handleUserInfo();
    },[]);
    const getPageQuery = () => {
        return window.location.href.split('?')[1]
    }

    const handleActive = async()  => {
        // const {location: {query: {a,b,c}}} = props;
        let allParams = getPageQuery()
        console.log(allParams)

        if (allParams === undefined) {
            window.history.back(-1)
        }

        let activeKey = allParams.split('=')[0]
        let activeToken = allParams.split('=')[1] // token
        if (activeKey !== 'token') {
            window.history.back(-1)
        }

        const data = {
            "token": activeToken
        }
        const result = await axios({
            method: "patch",
            url: `/api/user/active/`,
            headers: {
            'Content-Type': 'application/json',
            },
            data
        });

        if (result.data['status'] === "successed"){
            ActiveSuccessed(result.data['message'])
            navigate("/login")
        } else {
            ActiveFailed(result.data['message'])
        }
        // console.log(b)
        // console.log(c)
        setIsLoading(true)
    }

    const handleUserInfo = async()  => {
        const data = {
            "email": localStorage.getItem('email')
        }
        const result = await axios.get("/api/userinfo/", {params: data, headers: {
            "dorafactory-token": localStorage.getItem('token'),
        },});
        // const result = await axios({
        //     method: "post",
        //     url: `/api/userinfo/`,
        //     headers: {
        //     'Content-Type': 'application/json',
        //     "dorafactory-token": localStorage.getItem('token'),
        //     },
        //     data
        // });

        if (result.data['status'] === "successed"){
            // navigate("/home")
            localStorage.setItem("token", localStorage.getItem('token'))
            if (result.data['data']['is_active'] === false) {
                navigate("/verify_email")
            } else if (result.data['data']['otp_enabled'] === null) {
                navigate("/enable")
            } else if (result.data['data']['otp_enabled'] === true) {
                if (result.data['data']['otp_2fa_login_required'] === true) {
                    navigate("/home")
                } else {
                    navigate("/verify_code")
                }
            } else {
                navigate("/home")
            }
        }
    }

    if (isLoading) {
        return(
                <div className="login-card signup-card">
                    <img src={SignUpSvg}/>
                    <div className='signup-title'>Active your Account</div>
                </div>
        )
    } 

}

export default ActiveAccountCard;
