import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';

import SignUpSvg from '../../resources/signup.svg';
const Enable2FaCard = () => {
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const handleEnable = () => {
        navigate("/set2fa")
    }

    const handleSkip = async () => {
        await axios({
            method: "patch",
            url: `/api/otp/disable/`,
            baseURL: "https://app-dev.dorafactory.org",
            headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
            },
        });
        navigate("/home")
    }

    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        handleUserInfo();
    },[]);

    const handleUserInfo = async()  => {
        try {
            const result = await axios.get("/api/userinfo/", {
                baseURL: "https://app-dev.dorafactory.org",
                headers: {
                "dorafactory-token": localStorage.getItem('token'),
            },});

            if(result.data['status'] === "successed"){
                setUserInfo(JSON.stringify(result.data['data']))

                if (result.data['data']['is_active'] === false) {
                    navigate("/verify_email")
                // } else if (result.data['data']['otp_enabled'] === null) {
                    // navigate("/enable")
                } else if (result.data['data']['otp_enabled'] === true) {
                    if (result.data['data']['otp_2fa_login_required'] === true) {
                        navigate("/home")
                    } else {
                        navigate("/verify_code")
                    }
                } else if(result.data['data']['otp_enabled'] === false) {
                    navigate("/home")
                }
            } else {
                navigate("/login")
            }
            setIsLoading(true)
        } catch {
            navigate("/login")
        }
    }
    if (isLoading) {
        return(
            <div className="login-card signup-card">
                <img src={SignUpSvg}/>
                <div className='signup-title'>Welcome to</div>
                <div className='signup-title-base'>Dora Factory</div>
                <div className="code-2fa-body-1">It is strongly recommended to enable the two-factor authentication (2FA) to protect your account and secures your sensitive data.</div>
                <div className="login-btn-base login-btn-background enable-2fa-btn" onClick={handleEnable}>
                        Enable 2FA
                </div>
                <div className="login-btn-base skip-btn" onClick={handleSkip}>
                        Skip
                </div>
            </div>
        )
    }
}

export default Enable2FaCard;