import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
// import '../../styles/antd-message.scss';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const LoginSuccessed = (msg) => {
  message.success(`${msg}, Login successed`, 3);
};

const LoginFailed = (msg) => {
  message.error(`${msg}, Login failed.`);
};

const InputError = (msg) => {
    message.error(`${msg}`, 3);
};

const NoThreshold = () => {
    message.error('No threshold of this multisig wallet, please set it');
};

const InvalidThreshold = () => {
    message.error('This is a invalid threshold, please set a correct threshold');
}

const LoginUserCard = () => {
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();


    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyDownLogin = (event) => {
        if (event.keyCode === 13) {
            handleSignInMessage();
          }
    }

    useEffect(() => {
        handleUserInfo();
    },[]);

    const handleUserInfo = async()  => {
        try {

            const result = await axios.get("/api/userinfo/", {
                baseURL: "https://app-dev.dorafactory.org",
                headers: {
                    "dorafactory-token": localStorage.getItem('token'),
            },});

            if(result.data['status'] === "successed"){
                navigate("/home")
            } else {
                navigate("/login")
            }
            setIsLoading(true)
        } catch {
            setIsLoading(true)
            navigate("/login")
        }
    }
    


    const handleSignInMessage = async()  => {
        if (email === '' || password === '' || email === undefined || password === undefined) {
            InputError("Email and password cannot be empty!")
            return
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = regex.test(email);
        if (!isValidEmail) {
            InputError("Please enter the correct email address.")
            return
        }

        const data = {
            "email": email, 
            "password": password
        };
        const result = await axios(
            {
                method: "post",
                url: '/api/login/',
                baseURL: "https://app-dev.dorafactory.org",
                headers: {
                'Content-Type': 'application/json',
                },
                data
            });

        if(result.data.code === 'error') {
            LoginFailed(result.data.detail)
        } else {
            LoginSuccessed(email)
        }

        if(result.data['status'] === 'successed'){
            localStorage.setItem('email', email)
            localStorage.setItem("token", result.data['token'].toString())
            if (result.data['data']['is_active'] === false) {
                navigate("/verify_email")
            } else if (result.data['data']['otp_enabled'] === null) {
                navigate("/enable")
            } else if (result.data['data']['otp_enabled'] === true) {
                if (result.data['data']['otp_2fa_login_required'] === true) {
                    navigate("/home")
                } else {
                    navigate("/verify_code")
                }
            } else if (result.data['data']['otp_enabled'] === false) {
                navigate("/home")
            }
        }
    }
    
    const handleSignup = () => {
        navigate("/signup")
    }

    if (isLoading) {
        return(
            <div className="login-card signup-card">
                <img src={SignUpSvg}/>
                <div className='signup-title'>Sign in</div>
                <div className="description">New to Dora Factory? {" "}<a className='private-info-link' href="/signup">Create your account.</a></div>

            <FormControl sx={{ m: 0, width: '360px' }} variant="outlined" size="small">
            <OutlinedInput
                id="outlined-adornment-weight"
                className="email-value"
                onKeyDown={(e) => handleKeyDownLogin(e)}
                value={email}
                onChange={(e) => handleEmailChange(e)}
                aria-describedby="outlined-weight-helper-text"
                placeholder="Enter your email address"
                inputProps={{
                'aria-label': 'weight',
                }}
            />
            </FormControl>
            <FormControl sx={{ m: 0, width: '360px', minHeight: '48px', }} variant="outlined" size="small">
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                inputProps={{ 'aria-label': 'Without label' }}
                placeholder="Enter your password"
                className='password-value'
                value={password} 
                onKeyDown={(e) => handleKeyDownLogin(e)}
                onChange={(e) => handlePasswordChange(e)}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
                displayEmpty
            />
            </FormControl>
                <div className={"login-btn-base login-btn-background login-btn-choose"} onClick={() => handleSignInMessage()}>
                    <div className={"check-signup-font"}>
                        Sign In
                    </div>
                </div>
                <div className="text-center private-info-link-bottom"><a  href="/reset">Forgot password?</a></div>
            </div>
        )
    }
}


export default LoginUserCard;