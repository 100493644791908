// import SideMenu from '../components/sideMenu'
import AccountResetActiveCard from '../components/login/reset_password_active'
import '../styles/wallet.scss'

function ResetActive () {
  return (
    <div>
        <AccountResetActiveCard></AccountResetActiveCard>
    </div>
  )
}

export default ResetActive