// import SideMenu from '../components/sideMenu'
import TabSwitcher from '../components/user/pgs_tabs'
import '../styles/wallet.scss'

function UserPage () {
  return (
    <div>
        <TabSwitcher></TabSwitcher>
    </div>
  )
}

export default UserPage
