// import SideMenu from '../components/sideMenu'
import SignUpCard from '../components/login/signup'
import '../styles/wallet.scss'

function SignUp () {
  return (
    <div>
        <SignUpCard></SignUpCard>
    </div>
  )
}

export default SignUp
