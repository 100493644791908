// import SideMenu from '../components/sideMenu'
import ChangePasswordCard from '../components/login/change_password'
import '../styles/wallet.scss'

function ChangePassword () {
  return (
    <div>
        <ChangePasswordCard></ChangePasswordCard>
    </div>
  )
}

export default ChangePassword
