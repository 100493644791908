import React, { useState } from 'react';

import '../../styles/login.scss';
import axios from 'axios';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const InputError = (msg) => {
  message.error(`${msg}`, 3);
};

const SendEmailSuccess = (email) => {
  message.success(`reset password link already send to ${email}.`, 3);
};
const ResetPasswordCard = () => {
  const [email, setEmail] = useState();

  const handleEmailChange = (event) => {
      const value = event.target.value;
      setEmail(value);
  }

  const handleSendResetLink = async()  => {
    if (email === '' || email === undefined ) {
        InputError("Email cannot be empty!")
        return
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(email);
    if (!isValidEmail) {
        InputError("Please enter the correct email address.")
        return
    }

    const data = {
        "email": email,
    };

    const result = await axios(
        {
            method: "post",
            url: '/api/forget_password/',
            headers: {
            'Content-Type': 'application/json'
            },
            data
        });

    if(result.data['status'] === 'successed'){
        SendEmailSuccess(email)
    } else {
      InputError("Mail send failed, please check the network connection.")
    }
    console.log(result)
    
}

    return(
        <div className="login-card signup-card">
            <img src={SignUpSvg}/>
            <div className='signup-title'>Reset password</div>
            <div className="code-2fa-body-1">Already got your password back? <a className='private-info-link' href="/login">Sign in.</a></div>
            <FormControl sx={{ m: 0, width: '360px' }} variant="outlined" size="small">
          <OutlinedInput
            id="outlined-adornment-weight"
            className="email-value"
            // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            placeholder="Enter your email address"
            value={email}
            // onKeyDown={(e) => handleKeyDownSignUp(e)}
            onChange={(e) => handleEmailChange(e)}
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
            <div className="login-btn-base login-btn-background login-btn-choose check-signup-font" onClick={handleSendResetLink}>
                    Send Reset Link
            </div>
        </div>
    )
}

export default ResetPasswordCard;