// import SideMenu from '../components/sideMenu'
function Home () {
  return (
    <div>
        <h2>Hello World</h2>
    </div>
  )
}

export default Home
