import React, {useEffect, useLayoutEffect,useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from '../../resources/copy.svg'

import '../../styles/login.scss';

import axios from 'axios';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import QRCode from "qrcode";
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const CopySuccess = () => {
    message.success('Copied', 1);
};

const VerifyFailed = () => {
    message.error('Confirmation failed, please enter the correct otp code.', 1);
};

const Set2FaCard = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [qrcodeUrl, setqrCodeUrl] = useState("");
    
    const [base32Code, setbase32Code] = useState("");
    const [backupCodes, setbackupCodes] = useState([]);
    const [otpAuthUrl, setotpAuthUrl] = useState("");
    const [token, setToken] = useState();

    const [userInfo, setUserInfo] = useState();

    // const [email, setEmail] = useState();
    // const [is_active, setIsActive] = useState();
    // const [created_at, setCreatedAt] = useState();
    // const [otp_enabled, setOtpEnabled] = useState();
    // const [otp_verified, setOtpVerified] = useState();

    const handleTokenChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setToken(value);
    }

    const navigate = useNavigate();

    useLayoutEffect(() => {
        handleUserInfo();
        handleGetQrCode(); // this will fire on every change :(
    },[]);

    const handleUserInfo = async()  => {
        try {

            const result = await axios.get("/api/userinfo/", {headers: {
                "dorafactory-token": localStorage.getItem('token'),
            },});

            console.log(result.data)
            if(result.data['status'] === "successed"){
                setUserInfo(JSON.stringify(result.data['data']))
                if (result.data['data']['is_active'] === false) {
                    navigate("/verify_email")
                } else if (result.data['data']['otp_enabled'] === true) {
                    if (result.data['data']['otp_2fa_login_required'] === true) {
                        navigate("/home")
                    } else {
                        navigate("/verify_code")
                    }
                } else if(result.data['data']['otp_enabled'] === false) {
                    navigate("/home")
                }
            } else {
                navigate("/login")
            }
            setIsLoading(true)
        } catch {
            navigate("/login")
        }
    }
      
    const handleGetQrCode = async()  => {
        const result = await axios({
            method: "patch",
            url: `/api/otp/generate/`,
            headers: {
                'Content-Type': 'application/json',
                "dorafactory-token": localStorage.getItem('token'),
            },
        });
        // localStorage.setItem("token", result.data['token'].toString())
        console.log("----------------------")
        console.log(result.data)
        if(result.data['otpauth_url']){
            setotpAuthUrl(result.data['otpauth_url'])
            setbase32Code(result.data['base32'])
            setbackupCodes(result.data['backup_codes'])
            // ["6XuR3b", "3jg7CQ", "KpIqAi", "9f0KcL", "n1x0qX"]
            // "6XuR3b 3jg7CQ KpIqAi 9f0KcL n1x0qX"
            QRCode.toDataURL(result.data['otpauth_url']).then(setqrCodeUrl);
        }
    }

    const CancelToLoginPage = () => {
        window.history.back(-1)
    }

    const handleKeyDownConfirm2FA = (event) => {
        if (event.keyCode === 13) {
            handleConfirm2FA();
          }
    }

    const handleConfirm2FA = async () => {
        const data = {
            "token": token,
        }
        const result = await axios({
            method: "patch",
            url: `/api/otp/verify/`,
            headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
            },
            data
        });

        if (result.data['status'] === 'successed') {
            navigate("/home")
        } else {
            VerifyFailed()
        }
    }
    if (isLoading) {
        return(
            <div className="login-card signup-card">
                <img
                    className="enable-2fa-qrcode-img"
                    src={qrcodeUrl}
                    alt="qrcode url"
                />
                <div className='enable-2fa-title'>Enable 2FA</div>
                {/* <div className='signup-title-base'>Dora Factory</div> */}
                <div className="code-2fa-body-up">To enable 2FA, scan the QR code above with your favorite <a className='private-info-link' href="https://www.nytimes.com/wirecutter/reviews/best-two-factor-authentication-app/" target='_blank'>authentication app.</a></div>
                <FormControl sx={{ m: 0, width: '360px' }} variant="outlined" size="small">
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        className="email-value"
                        aria-describedby="outlined-weight-helper-text"
                        value={token}
                        onKeyDown={(e) => handleKeyDownConfirm2FA(e)}
                        onChange={(e) => handleTokenChange(e)}
                        placeholder="Enter the 6-digit code"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
                <div className='backup-code-title'>Backup codes</div>
                <div className="code-2fa-body-down">The backup codes can be used for sign-in if you lose the access to your authenticator.</div>

                <div className='enable-2fa-bottom'>
                    <div className='enable-2fa-bottom-font'>
                    {backupCodes.join(" ")}
                    <CopyToClipboard
                        text={backupCodes.join(" ")}
                    >
                        <img className='cursor-pointer enable-2fa-copy' src={copy} onClick={CopySuccess}/>
                    </CopyToClipboard>
                    </div>
                </div>

                <div className="login-btn-base login-btn-background enable-2fa-botton-btn" onClick={handleConfirm2FA}>
                        Confirm to Enable
                </div>
                <div className="login-btn-base enable-2fa-Cancel-btn" onClick={CancelToLoginPage}>
                        Cancel
                </div>
            </div>
        )
    }
}

export default Set2FaCard;