import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const ResendSuccessed = (msg) => {
  message.success(`${msg}, Resend successed`, 3);
};

const ResendFailed = (msg) => {
  message.error(`${msg}, Resend failed.`);
};

const VerifyEmailCard = () => {

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState();
    const [emailState, setEmailState] = useState();


    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        handleUserInfo();
    },[]);

    const handleUserInfo = async()  => {
        const data = {
            "email": localStorage.getItem('email')
        }
        setEmailState(localStorage.getItem('email'))
        try {
            const result = await axios.get("/api/userinfo/", {params: data, 
                baseURL: "https://app-dev.dorafactory.org",
                headers: {
                "dorafactory-token": localStorage.getItem('token'),
            },});
    
            if (result.data['status'] === "successed"){
                setUserInfo(JSON.stringify(result.data['data']))
    
                if (result.data['data']['is_active'] === true) {

                    if (result.data['data']['otp_enabled'] !== null) {
                        if (result.data['data']['otp_2fa_login_required'] === true) {
                            navigate("/home")
                        } else {
                            navigate("/verify_code")
                        }
                    } else {
                        navigate("/home")
                    }
                }
            } else {
                navigate("/login")
            }
            setIsLoading(true)
        } catch {
            navigate("/login")
        }
    }

    const handleResendMail = async()  => {
        let email =  localStorage.getItem('email');
        const data = {
            "email": email
        };
        const result = await axios(
            {
                method: "post",
                url: '/api/resend_email/',
                baseURL: "https://app-dev.dorafactory.org",
                headers: {
                'Content-Type': 'application/json'
                },
                data
            });

        if(result.data.code === 'error') {
            ResendFailed(result.data.detail)
        }

        if (result.data['status'] === 'failed') {
            ResendFailed(result.data['message'])
            if (result.data['active'] === true) {
                navigate("/enable")
            }
        }

        if(result.data['status'] === 'successed'){
            ResendSuccessed(email)
        }
    }

    if (isLoading) {
        return(
                <div className="login-card signup-card">
                    <img src={SignUpSvg}/>
                    <div className='signup-title'>Verify your email</div>
                    <div className="code-2fa-body-1">We just sent an email to {emailState}.</div>
                    <div className="verify-email-body">Check your email to receive the confirmation link.</div>
                    <div className="login-btn-base login-btn-background login-btn-choose verify-email-btn" onClick={() => handleResendMail()}>
                            Resend Email
                    </div>
                </div>
        )
    } 

}

export default VerifyEmailCard;
