import React, {useEffect, useState} from 'react';
import '../../styles/login.scss';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignUpSvg from '../../resources/signup.svg';
const ChangePasswordCard = () => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
    }

    return(
        <div className="login-card signup-card">
            <img src={SignUpSvg}/>
            <div className='signup-title'>Change your password</div>
            <div className="code-2fa-body-1">Already got your password back? <a className='private-info-link' href="/code2fa">Sign in.</a></div>
            <FormControl sx={{ m: 0, width: '360px', minHeight: '48px', }} variant="outlined" size="small">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            inputProps={{ 'aria-label': 'Without label' }}
            placeholder="Enter your new password"
            className='password-value'
            value={password}
            onChange={(e) => handlePasswordChange(e)}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            displayEmpty
          />
        </FormControl>
            <div className={"login-btn-base login-btn-background login-btn-choose"} >
                <div className={"check-signup-font"}>
                    Confirm
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordCard;