
import React, { useEffect, useState,  } from 'react';
// import './TabSwitcher.css';
import '../../styles/TabSwitcher.scss';
import axios from 'axios';
import { message } from 'antd';
import '../../styles/antd-message.scss';

import { useNavigate, Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import * as typhonjs from '@stricahq/typhonjs'

const sei_chain_params = {
  "chainId": "atlantic-2",
  "chainName": "sei atlantic 2",
  "rpc": "https://rpc.atlantic-2.seinetwork.io/",
  "rest": "https://rest.atlantic-2.seinetwork.io/",
  "bip44": {
    "coinType": 505
  },
  "coinType": 505,
  "bech32Config": {
    "bech32PrefixAccAddr": "sei",
    "bech32PrefixAccPub": "seipub",
    "bech32PrefixValAddr": "seivaloper",
    "bech32PrefixValPub": "seivaloperpub",
    "bech32PrefixConsAddr": "seivalcons",
    "bech32PrefixConsPub": "seivalconspub"
  },
  "currencies": [
    {
      "coinDenom": "SEI",
      "coinMinimalDenom": "usei",
      "coinDecimals": 6,
      "coinGeckoId": "unknown"
    }
  ],
  "feeCurrencies": [
    {
      "coinDenom": "SEI",
      "coinMinimalDenom": "usei",
      "coinDecimals": 6,
      "coinGeckoId": "unknown",
      "gasPriceStep": {
        "low": 0.01,
        "average": 0.025,
        "high": 0.03
      }
    }
  ],
  "gasPriceStep": {
    "low": 0.01,
    "average": 0.025,
    "high": 0.03
  },
  "stakeCurrency": {
    "coinDenom": "SEI",
    "coinMinimalDenom": "usei",
    "coinDecimals": 6,
    "coinGeckoId": "unknown"
  },
  "features": []
}

const dymension_chain_params = {
  "chainId": "35-C",
  "chainName": "Dymension Hub 35-C",
  "rpc": "https://rpc-hub-35c.dymension.xyz",
  "rest": "https://rest-hub-35c.dymension.xyz",
  "currencies": [
    {
      "coinDenom": "DYM",
      "coinMinimalDenom": "udym",
      "coinDecimals": 6
    }
  ],
  "stakeCurrency": {
    "coinDenom": "DYM",
    "coinMinimalDenom": "udym",
    "coinDecimals": 6
  },
  "coinType": 118,
  "bip44": {
    "coinType": 118
  },
  "bech32Config": {
    "bech32PrefixAccAddr": "dym",
    "bech32PrefixAccPub": "dympub",
    "bech32PrefixValAddr": "dymvaloper",
    "bech32PrefixValPub": "dymvaloperpub",
    "bech32PrefixConsAddr": "dymvalcons",
    "bech32PrefixConsPub": "dymvalconspub"
  },
  "beta": true,
  "feeCurrencies": [
    {
      "coinDenom": "DYM",
      "coinMinimalDenom": "udym",
      "coinDecimals": 6,
      "gasPriceStep": {
        "low": 0.25,
        "average": 0.4,
        "high": 0.55
      }
    }
  ]
}

const althea_chain_params = {
"chainId": "althea_7357-1",
"chainName": "Althea Testnet",
"rpc": "https://althea-testnet.nodejumper.io:443",
"rest": "https://althea-testnet.nodejumper.io:1317",
"bip44": {
  "coinType": 118
},
"coinType": 118,
"bech32Config": {
  "bech32PrefixAccAddr": "althea",
  "bech32PrefixAccPub": "altheapub",
  "bech32PrefixValAddr": "altheavaloper",
  "bech32PrefixValPub": "altheavaloperpub",
  "bech32PrefixConsAddr": "altheavalcons",
  "bech32PrefixConsPub": "altheavalconspub"
},
"currencies": [
  {
    "coinDenom": "ALTHEA",
    "coinMinimalDenom": "ualthea",
    "coinDecimals": 18,
    "coinGeckoId": "unknown"
  }
],
"feeCurrencies": [
  {
    "coinDenom": "ALTHEA",
    "coinMinimalDenom": "ualthea",
    "coinDecimals": 18,
    "coinGeckoId": "unknown",
    "gasPriceStep": {
      "low": 0.01,
      "average": 0.025,
      "high": 0.03
    }
  }
],
"gasPriceStep": {
  "low": 0.01,
  "average": 0.025,
  "high": 0.03
},
"stakeCurrency": {
  "coinDenom": "ALTHEA",
  "coinMinimalDenom": "ualthea",
  "coinDecimals": 18,
  "coinGeckoId": "unknown"
},
"features": []
}

const asset_mantle_chain_params = {
"chainId": "mantle-1",
"chainName": "AssetMantle",
"chainSymbolImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/mantle/chain.png",
"nodeProvider": {
  "name": "mantleNode",
  "email": "hello@assetmantle.one",
  "website": "https://assetmantle.one/"
},
"rpc": "https://rpc.assetmantle.one",
"rest": "https://rest.assetmantle.one",
"bip44": {
  "coinType": 118
},
"bech32Config": {
  "bech32PrefixAccAddr": "mantle",
  "bech32PrefixAccPub": "mantlepub",
  "bech32PrefixValAddr": "mantlevaloper",
  "bech32PrefixValPub": "mantlevaloperpub",
  "bech32PrefixConsAddr": "mantlevalcons",
  "bech32PrefixConsPub": "mantlevalconspub"
},
"currencies": [
  {
    "coinDenom": "MNTL",
    "coinMinimalDenom": "umntl",
    "coinDecimals": 6,
    "coinGeckoId": "assetmantle",
    "coinImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/mantle/chain.png"
  }
],
"feeCurrencies": [
  {
    "coinDenom": "MNTL",
    "coinMinimalDenom": "umntl",
    "coinDecimals": 6,
    "coinGeckoId": "assetmantle",
    "coinImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/mantle/chain.png",
    "gasPriceStep": {
      "low": 0.01,
      "average": 0.025,
      "high": 0.04
    }
  }
],
"stakeCurrency": {
  "coinDenom": "MNTL",
  "coinMinimalDenom": "umntl",
  "coinDecimals": 6,
  "coinGeckoId": "assetmantle",
  "coinImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/mantle/chain.png"
},
"features": [],
"walletUrl": "https://wallet.assetmantle.one",
"walletUrlForStaking": "https://wallet.assetmantle.one/stake"
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  outline: "none",
  p: 4,
  'display': 'flex',
'flex-direction': 'column',
'align-items': 'center',
'padding': '48px',
'isolation': 'isolate',

'width': '456px',
'height': '368px',

/* basic/bg-primary */

'background': '#FFFFFF',
/* elevation/elevation-2 */

'box-shadow': '0px 8px 16px rgba(33, 33, 44, 0.04), 0px 4px 12px rgba(33, 33, 44, 0.04), 0px 2px 8px rgba(33, 33, 44, 0.04), 0px 1px 4px rgba(33, 33, 44, 0.04), 0px 0px 0.5px rgba(33, 33, 44, 0.08)',
'border-radius': '16px',
};

message.config({
    top:100,
    duration:2
})

const NoWallet = (wallet_name) => {
  message.error(`Please install ${wallet_name} extension.`, 3);
};


const BindAddressSuccess = (network, address) => {
  message.success(`Bind ${network} address success: ${address}`, 3);
};

const BindAddressFailed = (network) => {
  message.error(`Bind ${network} address failed.`, 3);
};


function AddressesTab(props) {
    const [isWalletLinked, setIsWalletLinked] = useState(false);
    const [linkedAddresses, setLinkedAddresses] = useState([]);
    const navigate = useNavigate();

    const [switchLinkNetwork, setSwitchLinkAddress] = useState({});
    const [switchLinkAccount, setSwitchLinkAccount] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const [open, setOpen] = React.useState(false);
    
    const handleOpen = (networkName) => {
      setSwitchLinkAddress(networkName)
      setOpen(true);
    };

    const handleClose = () => {
      setSwitchLinkAddress({})
      setSwitchLinkAccount("")
      setOpen(false);
    };

    const handleCancelSwitch = () => {
      // setSwitchLinkAddress({})
      setSwitchLinkAccount("")
    };


    useEffect(() => {
      handleUserAddressesInfo();
      handleNetworkLists()
    },[]);
    
    const handleUserAddressesInfo = async()  => {
      try {
        const result = await axios.get(`/api/list_address/`, {
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
          "dorafactory-token": localStorage.getItem('token'),
        },});
        console.log(result)
        if (result.data.code === 'error') {
          navigate("/login")
        }
        setLinkedAddresses(result.data.data)
        setIsLoading(true);
      } catch (error) {
        console.error(`Failed to fetch list of addresses: ${error.message}`);
        navigate("/login")
      }
    }
    const [networkLists, setNetworkLists] = useState([]);
    
    const handleNetworkLists = async () => {
      const chain_details = await axios.get(
        `https://pgs-api.dorafactory.org/validators/?is_only=True`
      );
      let networkList = chain_details.data.data.map(chain_detail => {
        if (chain_detail.support_staking===true) {
          return {
            "name": chain_detail.name,
            "img_name": chain_detail.network,
            "network_type": chain_detail.network_type,
            "chain_id": chain_detail.chain_id,
            "type": chain_detail.chain_type
          }
        }
        return undefined; // 返回 undefined
      }).filter(item => item !== undefined); // 删除 undefined 元素
      console.log(networkList);
      setNetworkLists(networkList);
    }
    

    // const handleNetworkLists = async () => {
    //   const chain_details = await axios.get(
    //     `https://pgs-api.dorafactory.org/validators/`
    //   );
    //   let networkList = chain_details.data.data.map(chain_detail => {
    //     if (chain_detail.support_staking===true) {
    //       return {
    //         "name": chain_detail.name,
    //         "img_name": chain_detail.network,
    //         "chain_id": chain_detail.chain_id,
    //         "type": chain_detail.chain_type
    //       }
    //     }
    //   })
    //   console.log(networkList)
    //   setNetworkLists(networkList);
    // }

    function buf2hex(buffer) { // buffer is an ArrayBuffer
      return [...new Uint8Array(buffer)]
          .map(x => x.toString(16).padStart(2, '0'))
          .join('');
    }
    

  //   const linkUserAddress = async(network_detail) => {
  //     const message = await axios.get(`/api/bind_address/?email=${localStorage.getItem('email')}`, {headers: {
  //       "dorafactory-token": localStorage.getItem('token'),
  //     },});
  //     console.log(message);
  //     console.log(message.data.message);

  //     console.log(network_detail.type)
  //     if (network_detail.type === "cosmos") {
  //       linkUserCosmosAddress(network_detail.name, network_detail.chain_id, message.data.message)
  //     } else if (network_detail.type === "aptos") {
  //       linkUserAptosAddress(message.data.message)
  //     }
  // }


  const handleConfirm = async() => {
    if (switchLinkNetwork.type === "cosmos") {
      handleConfirmCosmos()
    } else if (switchLinkNetwork.type === "aptos") {
      handleConfirmAptos()
    } else if (switchLinkNetwork.type === "cardano") {
      handleConfirmCardano()
  }
}

    
    const handleConfirmCosmos = async() => {
      if (!window.keplr) {
        NoWallet("keplr")
    } else {
      let chainId = switchLinkNetwork.chain_id
      let network = switchLinkNetwork.name
      if (network === 'Sei') {
        await window.keplr.experimentalSuggestChain(sei_chain_params);
        chainId = sei_chain_params.chainId;
      }
      if (network === 'Dymension') {
        await window.keplr.experimentalSuggestChain(dymension_chain_params);
        chainId = dymension_chain_params.chainId;
      }
      if (network === 'Althea') {
        await window.keplr.experimentalSuggestChain(althea_chain_params);
        chainId = althea_chain_params.chainId;
      }
      if (network === 'AssetMantle') {
        await window.keplr.experimentalSuggestChain(asset_mantle_chain_params);
        chainId = asset_mantle_chain_params.chainId;
      }
        await window.keplr.enable(chainId);

        const offlineSigner = window.keplr.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();
        setSwitchLinkAccount(accounts[0].address)
    }
  }

    const handleConfirmAptos = async() => {
      if (!window.aptos){
        NoWallet("petra")
      } else {
        const wallet = window.aptos;
        let connectRp = await wallet.connect()
        console.log(connectRp)
        setSwitchLinkAccount(connectRp.address)
      }
    }

    const handleConfirmCardano = async () => {
      if (!window.cardano.typhon){
        NoWallet("typhon")
      } else {
        await window.cardano.typhon.enable();
        const address = await window.cardano.typhon.getRewardAddress();
        setSwitchLinkAccount(address.data);
      }
  }

  const linkUserAddress = async() => {
    const message = await axios.get(`/api/bind_address/?email=${localStorage.getItem('email')}`, {
      baseURL: "https://app-dev.dorafactory.org",
      headers: {
      "dorafactory-token": localStorage.getItem('token'),
    },});
    console.log(message);
    console.log(message.data.message);

    console.log(switchLinkNetwork.type)
    if (switchLinkNetwork.type === "cosmos") {
      linkUserCosmosAddress(switchLinkNetwork.name, switchLinkNetwork.chain_id, message.data.message)
    } else if (switchLinkNetwork.type === "aptos") {
      linkUserAptosAddress(message.data.message)
    } else if (switchLinkNetwork.type === "cardano") {
      linkUserCardanoAddress(message.data.message)
    }
}
  
    const linkUserAptosAddress = async(message) => {
        const nonce = '';
        const response = await window.aptos.signMessage({
          message,
          nonce,
        });
        const { publicKey } = await window.aptos.account();
        console.log(response.fullMessage)
        console.log(response.signature)
        // Remove the 0x prefix
        const key = publicKey.slice(2, 66);
        console.log(key)

        let data = {
          "signature": response.signature,
          "public_key": key,
          "network": "Aptos",
        }
        const result = await axios(
          {
              method: "post",
              url: '/api/bind_address/',
              baseURL: "https://app-dev.dorafactory.org",
              headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
              },
              data
          });

        if (result.data.status === 'success') {
          setLinkedAddresses(result.data.message)
          BindAddressSuccess("Aptos", switchLinkAccount)
          handleClose()
        } else {
          BindAddressFailed("Aptos")
          handleClose()
        }
    }

    const linkUserCosmosAddress = async(network, chain_id, message) => {
        let keplr_signature = await window.keplr.signArbitrary(chain_id, switchLinkAccount, message)
        console.log(keplr_signature)
        let data = {
          "signature": keplr_signature,
          "address": switchLinkAccount,
          "network": network,
        }
        const result = await axios(
          {
              method: "post",
              url: '/api/bind_address/',
              baseURL: "https://app-dev.dorafactory.org",
              headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
              },
              data
          });

        if (result.data.status === 'success') {
          setLinkedAddresses(result.data.message)
          BindAddressSuccess(network, switchLinkAccount)
          handleClose()
        } else {
          BindAddressFailed(network)
          handleClose()
        }
    }

    const linkUserCardanoAddress = async (message) => {
        const api = await window.cardano.typhoncip30.enable();

        const hexAddresses = await api.getRewardAddresses();
        const hexAddress = hexAddresses[0];
  
        const messageHex = Buffer.from(message).toString("hex");
  
        const dataSignature = await api.signData(hexAddress, messageHex);
        console.log(dataSignature)

        let data = {
          "signature": dataSignature,
          "address": hexAddress,
          "network": "Cardano",
        }
        const result = await axios(
          {
              method: "post",
              url: '/api/bind_address/',
              baseURL: "https://app-dev.dorafactory.org",
              headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
              },
              data
          });

        if (result.data.status === 'success') {
          setLinkedAddresses(result.data.message)
          BindAddressSuccess("Cardano", switchLinkAccount)
          handleClose()
        } else {
          BindAddressFailed("Cardano")
          handleClose()
        }
    }

    const handleRemoveUserAddress = async(address, network) => {
      const data = {
        "address": address,
        "network": network
      }
      const result = await axios({
          method: "patch",
          url: `/api/bind_address/`,
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
            'Content-Type': 'application/json',
            "dorafactory-token": localStorage.getItem('token'),
          },
          data
      });

      setLinkedAddresses(result.data.message);
    }

    if (isLoading) {
      return (
        <div className="tab-pane">
          <div className="card-container">
            { linkedAddresses.map((linked_address, index) => {
              return (
  
                <div>

                { 
                  index === 0 ?
                  <>
                  <div className='linked-address-head'>
                    Linked addresses
                  </div>
                  <thead className='card-form-head card-detail-form-head'>
                      <tr className=''>
                        <th className="chain-name-form-head">WALLET ADDRESS</th>
                        <th className="chain-name-form-head">BLOCKCHAIN</th>
                      </tr>
                    </thead>
                  </>
        
                  : null
                }
                  <div className="card chain-form-card">
                  <tbody className=''>
                    <tr className="">
                    <th className="chain-name big-font">{linked_address.address.substring(0, 8) + '...' + linked_address.address.substring(linked_address.address.length-5,)}</th>
                        <th className="chain-name">{linked_address.network_name}</th>
                    </tr>
                  </tbody>
                  <div className="card-btn remove-btn" onClick={() => handleRemoveUserAddress(linked_address.address, linked_address.network_name)}>
                    Remove
                  </div>
                </div>
                
                </div>
              )

            })}
          
            <div className='select-address-head'>
            Select a blockchain to link wallet address
            </div>
            { networkLists.map(networkName => {
            return (<div className="card chain-form-card">
            <tbody className=''>
              <tr className="">
                  <img class="staking-ecosys-logo" src={`/data/staking/logo-${networkName.img_name}.png`} alt={networkName.name}/>
                  <th className="chain-name-list">{networkName.name}</th>
              </tr>
            </tbody>
                <div className="link-wallet-btn card-btn" onClick={() =>handleOpen(networkName)}>
                  Link Address
                </div>

          </div>
            )
            })}
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                <div className='modal-box-delete-btn' onClick={handleClose}>
                  <img src='/data/staking/icon-delete.png'/>
                </div>
                <div className='modal-box-container'>
                <div className='modal-box-head'>
                  Connect a wallet
                </div>
                {
                  switchLinkAccount==="" ?
                  <>
                  {
                    switchLinkNetwork.type === 'cosmos' ?
                    <div className='modal-box-wallet-img-box'>
                    <img className='modal-box-wallet-img' src='/data/staking/keplr-logo.png'/>
                    <p className='modal-box-wallet-name'>Keplr</p>
                  </div>

                    : switchLinkNetwork.type === 'aptos' ? (
                  <div className='modal-box-wallet-img-box'>
                    <img className='modal-box-wallet-img' src='/data/staking/petra-logo.png'/>
                    <p className='modal-box-wallet-name'>Petra</p>
                  </div>
                    )
                    : (
                  <div className='modal-box-wallet-img-box'>
                    <img className='modal-box-wallet-img' src='/data/staking/typhon-logo.png'/>
                    <p className='modal-box-wallet-name'>Typhon</p>
                  </div>)
                  }
                  </>
                  : 
                  <div className='modal-box-wallet-img-box-switched'>
                  {/* <img className='modal-box-wallet-img' src='/data/staking/keplr-logo.png'/> */}
                  {
                    switchLinkNetwork.type === 'cosmos' ?
                    <img className='modal-box-wallet-img' src='/data/staking/keplr-logo.png'/>
                    : switchLinkNetwork.type === 'aptos' ?
                    <img className='modal-box-wallet-img' src='/data/staking/petra-logo.png'/>
                    : 
                    <img className='modal-box-wallet-img' src='/data/staking/typhon-logo.png'/>
                  }
                  <div className='modal-box-switch-detail'>
                  <p className='modal-box-wallet-address-switched'>{switchLinkAccount.substring(0, 8) + '...' + switchLinkAccount.substring(switchLinkAccount.length-5,)}</p>
                  <div className='modal-box-wallet-network-parent'>
                  <p className='modal-box-wallet-network-switched'>{switchLinkNetwork.name} {switchLinkNetwork.network_type}</p>
                  </div>
                  </div>
                  <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/switch-icon.png'/>
                  </div>
                }
                <div className='modal-box-description'>
                  By confirming, you will be asked to sign a message in the wallet. No fee will be charged.
                </div>
                <div className='modal-box-btn-parent'>

                {
                  switchLinkAccount==="" ?
                  <div className='modal-box-confirm-btn' onClick={handleConfirm}>Confirm to Link</div>
                  :
                  <div className='modal-box-confirm-btn switched' onClick={linkUserAddress}>Confirm to Link</div>
                }
                  <div className='modal-box-cancel-btn' onClick={handleClose}>Cancel</div>
                </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      );
    }
  }

export default AddressesTab;
