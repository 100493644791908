// import SideMenu from '../components/sideMenu'
import Set2FaCard from '../components/login/set_2fa'
import '../styles/wallet.scss'

function Set2Fa () {
  return (
    <div>
        <Set2FaCard></Set2FaCard>
    </div>
  )
}

export default Set2Fa
