import './index.scss';
import Home from './pages/home';
import Login from './pages/login';
import SignUp from './pages/signup';
import LogOut from './pages/logout';
import Code2Fa from './pages/code_2fa';
import SignInByCode from './pages/signin_with_code';
import ChangePassword from './pages/change_password';
import ResetPassword from './pages/reset_password';
import Enable2Fa from './pages/enable_2fa';
import Set2Fa from './pages/set_2fa';
import VerifyEmail from './pages/verify_email';
import UserPage from './pages/user';
import ActiveAccount from './pages/active_account';
import ResetActive from './pages/reset_active';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Login/>}></Route>
          <Route path='/home' element={<UserPage/>}></Route>
          <Route path="/login/" element={<Login/>}></Route>
          <Route path="/logout" element={<LogOut/>}></Route>
          <Route path="/signup" element={<SignUp/>}></Route>
          <Route path="/verify_code" element={<Code2Fa/>}></Route>
          {/* <Route path="/signinbycode" element={<SignInByCode/>}></Route> */}
          <Route path="/change" element={<ChangePassword/>}></Route>
          <Route path="/reset" element={<ResetPassword/>}></Route>
          <Route path="/enable" element={<Enable2Fa/>}></Route>
          <Route path="/set2fa" element={<Set2Fa/>}></Route>
          <Route path="/verify_email" element={<VerifyEmail/>}></Route>
          <Route path="/user" element={<UserPage/>}></Route>
          <Route path="/active_account" element={<ActiveAccount/>}></Route>
          <Route path="/reset_active" element={<ResetActive/>}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default function App() {
  return (
    // <SubstrateContextProvider>
      <Main/>
    // </SubstrateContextProvider>
  )
};
