import React from 'react';
//  import './TabSwitcher.css';
import '../../styles/TabSwitcher.scss';

function FooterTab() {

  return (
    <footer class="footer">
      <nav class="footer-nav">
        <a class="footer-nav-item" target="_blank" rel="noopener external nofollow noreferrer" href="https://dorafactory.org/">
          <span class="footer-nav-item-label">© 2023 Dora Factory</span>
        </a>
      </nav>
    </footer> 
  );
}

export default FooterTab;
