
import React, { useState, useEffect } from 'react';
// import './TabSwitcher.css';
import '../../styles/TabSwitcher.scss';
import axios from 'axios';

import { message } from 'antd';
import '../../styles/antd-message.scss';
// import * as ethers from "ethers";
import { ethers, Contract } from "ethers";
import { useNavigate, Link } from 'react-router-dom';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const claimAbi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "months",
				"type": "uint256"
			}
		],
		"name": "Claim",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "_users",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_claimable",
				"type": "uint256[]"
			}
		],
		"name": "addClaimable",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_ts",
				"type": "uint256"
			}
		],
		"name": "balanceOfAt",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_ts",
				"type": "uint256"
			}
		],
		"name": "balanceOfAtFuture",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "months",
				"type": "uint256"
			}
		],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "claimable",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dora",
		"outputs": [
			{
				"internalType": "contract ERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract ERC20",
				"name": "_dora",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "initialize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "totalClaimed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_ts",
				"type": "uint256"
			}
		],
		"name": "totalSupplyAt",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_ts",
				"type": "uint256"
			}
		],
		"name": "totalSupplyAtFuture",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "totalWithdrew",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]


const claimContractAddress = "0x06e22202f929cD9972e74577092802C8907BD3A1"
const ethRpc = "https://goerli.infura.io/v3/762abb1be8104b51b9281b1ea6b3cac1"


message.config({
  top:100,
  duration:2
})

const NoWallet = (wallet_name) => {
  message.error(`Please install ${wallet_name} extension.`, 3);
};


const WrongAddress = (linked_address) => {
  message.error(`The linked address is ${linked_address.substring(0, 8) + '...' + linked_address.substring(linked_address.length-5,)}. Please change your wallet address.`, 3);
};

const WrongCTX = () => {
  message.error(`Please confirm the network and address you are connected to.`);
}

const WatingTx = () => {
  message.info(`Waiting for successful transaction execution`, 3);
};


const BindAddressSuccess = (network, address) => {
  message.success(`Bind ${network} address success: ${address}`, 3);
};

const BindAddressFailed = (network, msg) => {
  message.error(`Bind ${network} address failed. ${msg}`, 3);
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  outline: "none",
  p: 4,
  'display': 'flex',
'flex-direction': 'column',
'align-items': 'center',
'padding': '48px',
'isolation': 'isolate',

'width': '456px',
// 'height': '368px',

/* basic/bg-primary */

'background': '#FFFFFF',
/* elevation/elevation-2 */

'box-shadow': '0px 8px 16px rgba(33, 33, 44, 0.04), 0px 4px 12px rgba(33, 33, 44, 0.04), 0px 2px 8px rgba(33, 33, 44, 0.04), 0px 1px 4px rgba(33, 33, 44, 0.04), 0px 0px 0.5px rgba(33, 33, 44, 0.08)',
'border-radius': '16px',
};


const style_select = {
  "align-items": "center",
  "padding": "6px 4px 6px 4px",
  "width": "210px",
  "height": "36px",
  
  "border": "1px solid #E8E8EE",
  "border-radius": "18px"
};

const style_option = {
"width": "154px",
"height": "24px",

"font-family": "Public Sans",
"font-style": "normal",
"font-weight": "600",
"font-size": "16px",
"line-height": "24px",

  "color": "#21212C"

}

function IncentivesTab(props) {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    const [switchLinkAccount, setSwitchLinkAccount] = useState('');
    const [userAddressInfo, setUserAddressInfo] = useState();
    const [open, setOpen] = React.useState(false);
    
    const [openClaim, setOpenClaim] = React.useState(false);
    const [confirmClaim, setConfirmClaim] = React.useState(false);
    const [stakeAmount, setStakeAmount] = React.useState("0");
    const [stakeMonth, setStakeMonth] = React.useState(24);
    const [isUserAddr, setIsUserAddr] = React.useState(true);
    const [withdrawable, setWithdrawable] = React.useState("0.0000")
    const [openWithdraw, setOpenWithdraw] = React.useState(false)
    const [claimHistory, setClaimHistory] = React.useState([]);
    const [accountDetail, setAccountDetail] = React.useState({
      current_claimable: "0.0000",
      total_claimed: "0.0000",
      current_vcdora: "0.0000",
    });
    const [currentClaimable, setCurrentClaimable] = React.useState(0)

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setSwitchLinkAccount("")
      setOpen(false);
    };

    const handleOpenClaim = () => {
      setOpenClaim(true);
    };

    const handleOpenWithdraw = () => {
      setOpenWithdraw(true)
    }

    const handleCloseWithdraw = () => {
      setOpenWithdraw(false)
      setIsUserAddr(true)
    }

    const handleCloseClaim = () => {
      setStakeMonth(24)
      setOpenClaim(false);
      setConfirmClaim(false);
      setIsUserAddr(true)
      setStakeAmount(accountDetail.current_claimable)
    };


    const handleCancelConfirmCLaim = () => {
      setConfirmClaim(false);
      setSwitchLinkAccount("")
    };

  const handleStakeChange = (event) => {
    // const stakeArgs = event.target.value.split("[");
    const stakeArgs = event.target.value.split(",");

    console.log(event.target.value)
    console.log(stakeArgs)

    setStakeAmount(stakeArgs[0])
    setStakeMonth(stakeArgs[1])
  };


  const handleSettingDropdownClick = () => {
    props.handleSettingDropdownClick();
  }

    const handleCancelSwitch = () => {
      setSwitchLinkAccount("")
      setIsUserAddr(true)
    };


    const handleConfirmMetamask = async() => {
      if (!window.ethereum){
        NoWallet("MetaMask")
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum)

        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()
        // console.log(signer)
        let address = await signer.getAddress()
        console.log(address)

        setSwitchLinkAccount(address)
      }
    }


    const handleConfirmAddress = async() => {
      if (!window.ethereum){
        NoWallet("MetaMask")
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum)

        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner()
        // console.log(signer)
        let address = await signer.getAddress()
        setSwitchLinkAccount(address)
      }
    }

    useEffect(() => {
      handleUserAddressesInfo();
    },[]);
    
    const handleUserAddressesInfo = async () => {
      try {
        const result = await axios.get(`/api/list_address/?network=Ethereum`, {
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
            "dorafactory-token": localStorage.getItem('token'),
          },
        });

        if (result.data.code === 'error') {
          navigate("/login")
        }
        setUserAddressInfo(result.data.data)
        if (result.data.data !== undefined) {
          await handleUserOnChainInfo(result.data.data.address)
          await handleUserClaimHistory(result.data.data.address)
        }
        setIsLoading(true);
      } catch (error) {
        console.error(`Failed to fetch list of addresses: ${error.message}`);
        WrongCTX();
        setIsLoading(true);
        navigate("/login")
      }
    };
  
    const handleUserOnChainInfo = async (linked_address) => {
      const provider = new ethers.providers.JsonRpcProvider(ethRpc)

      const contract = new ethers.Contract(claimContractAddress, claimAbi, provider);
      let current_claimable_bytes = await contract.claimable(linked_address)
      let total_claimed_bytes = await contract.totalClaimed(linked_address)
      let current_vcdora_bytes = await contract.balanceOf(linked_address)
      let total_withdraw_bytes = await contract.totalWithdrew(linked_address)

      const withSigner = await contract.connect(linked_address);
      let withdrawable_bytes = await withSigner.callStatic.withdraw();

      let current_claimable = roundedUpNumber(ethers.utils.formatUnits(current_claimable_bytes, 18))
      let total_claimed = roundedUpNumber(ethers.utils.formatUnits(total_claimed_bytes, 18))
      let current_vcdora = roundedUpNumber(ethers.utils.formatUnits(current_vcdora_bytes, 18))
      let total_withdraw = roundedUpNumber(ethers.utils.formatUnits(total_withdraw_bytes, 18))
      let withdrawable = roundedUpNumber(ethers.utils.formatUnits(withdrawable_bytes, 18))
      console.log(`current_claimable ${current_claimable}`)
      console.log(`total_claimed ${total_claimed}`)
      console.log(`current_vcdora ${current_vcdora}`)
      console.log(`total_withdraw ${total_withdraw}`)
      console.log(`withdrawable ${withdrawable}`)
      setStakeAmount(current_claimable)
      setCurrentClaimable(current_claimable_bytes)
      let accountDetail =  {
        current_claimable,
        total_claimed,
        current_vcdora,
      }

      setAccountDetail(accountDetail)

      setWithdrawable(withdrawable);
    }
    function roundedUpNumber(number_str) {
      let number = parseFloat(number_str); // 将字符串转换为浮点数
      let roundedNumber = Math.ceil(number * 10000) / 10000; // 向上取整
      let formattedNumber = roundedNumber.toFixed(4); // 格式化为四位小数

      return formattedNumber;
    }

    const roundedUpNumberByMonth = (number_str, month) => {
      let number = parseFloat(number_str); // 将字符串转换为浮点数

      let multiplier = month / 24

      let roundedNumber = Math.ceil(number * 10000) / 10000 * multiplier; // 向上取整
      let formattedNumber = roundedNumber.toFixed(4); // 格式化为四位小数

      return `${formattedNumber},${month}`;
    }


    function formatDate(timestamp) {
      let date = new Date(timestamp * 1000); // 将时间戳转换为毫秒
    
      let year = date.getFullYear(); // 获取年份
      let month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，并补零
      let day = ('0' + date.getDate()).slice(-2); // 获取日期，并补零
    
      let formattedDate = year + '-' + month + '-' + day; // 格式化为 year-month-day
      return formattedDate;
    }

    const handleUserClaimHistory = async (linked_address) => {
      const provider = new ethers.providers.JsonRpcProvider(ethRpc)

      const contract = new ethers.Contract(claimContractAddress, claimAbi, provider);

      let claimEvents = await contract.queryFilter('Claim')

      let promises = claimEvents.map(async (claim_event) => {
        let user = claim_event.args["user"]

        if (user === linked_address) {
          let block_num = claim_event.blockNumber
          let block = await provider.getBlock(block_num);
          let date = formatDate(block.timestamp);

          let multiplier = parseInt(claim_event.args["months"], 16) / 24
          let claim_amount_number = ethers.utils.formatUnits(claim_event.args["amount"], 18) * multiplier
          let claim_amount = roundedUpNumber(claim_amount_number.toString())
          let tx_url = `https://goerli.etherscan.io/tx/${claim_event.transactionHash}`

          return {
            date,
            user,
            claim_amount,
            tx_url,
          }
        }
      })

      const resolvedPromises = await Promise.all(promises);
      let flattenedPromises = resolvedPromises.flat();
      
      if (flattenedPromises[0] === undefined) {
        flattenedPromises = []
      } else {
        flattenedPromises.sort(function(a, b) {
          let dateA = new Date(a.date);
          let dateB = new Date(b.date)
          return dateB - dateA
        })
      }

      setClaimHistory(flattenedPromises);
      setIsLoading(true);
    }

  const linkUserAddress = async() => {
    const message = await axios.get(`/api/bind_address/?email=${localStorage.getItem('email')}`, {
      baseURL: "https://app-dev.dorafactory.org",
      headers: {
      "dorafactory-token": localStorage.getItem('token'),
    },});

    console.log(message.data.message);

    handleLinkETHAddress(message.data.message)
}
  

const handleLinkETHAddress = async (message) => {
  try {

    const provider = new ethers.providers.Web3Provider(window.ethereum)

    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
  
    let address = await signer.getAddress()
    console.log(address)
  
    console.log(message)
    let signature = await signer.signMessage(message);
    console.log(signature)
  
    let data = {
      "signature": signature,
      "address": address,
      "network": "Ethereum",
    }
    const result = await axios(
      {
          method: "post",
          url: '/api/bind_address/',
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
          'Content-Type': 'application/json',
          "dorafactory-token": localStorage.getItem('token'),
          },
          data
      });
  
    if (result.data.status === 'success') {
      
      setUserAddressInfo({
        network_name: "Ethereum",
        address: switchLinkAccount
      });
      BindAddressSuccess("Ethereum", switchLinkAccount)
      handleUserOnChainInfo(switchLinkAccount);
      handleUserClaimHistory(switchLinkAccount)
      handleClose()
    } else {
      BindAddressFailed("Ethereum", result.data.message)
      handleClose()
    }
  } catch {
    console.log("reject sign")
  }
};


const handleUnlinkUserETHAddress = async(address) => {
  const data = {
    "address": address,
    "network": "Ethereum"
  }
  const result = await axios({
      method: "patch",
      url: `/api/bind_address/`,
      baseURL: "https://app-dev.dorafactory.org",
      headers: {
        'Content-Type': 'application/json',
        "dorafactory-token": localStorage.getItem('token'),
      },
      data
  });
  console.log(result)
  handleSettingDropdownClick();
  setUserAddressInfo(undefined);
}

// const handleConfirmClaimAddress = async() => {
//   if (!window.ethereum){
//     NoWallet("MetaMask")
//   } else {
//     const provider = new ethers.providers.Web3Provider(window.ethereum)

//     await provider.send("eth_requestAccounts", []);
//     const signer = provider.getSigner()
//     // console.log(signer)
//     let address = await signer.getAddress()
//     console.log(address)

//     setSwitchLinkAccount(address)
//   }
// }




const handleConfirmClaim = async () => {
  setConfirmClaim(true);
  setIsUserAddr(true)
}


const handleClaimDora = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)

  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner()

  let address = await signer.getAddress()
  console.log("====")
  console.log(address)

  if (address !== userAddressInfo.address) {
    WrongAddress(userAddressInfo.address)
    setIsUserAddr(false)
  } else {
    setIsUserAddr(true)
    const contract = new Contract(claimContractAddress, claimAbi, provider);
    const withSigner = await contract.connect(signer);
    console.log(withSigner)
    await withSigner.claim(currentClaimable, stakeMonth);
    handleCloseClaim();
    WatingTx();
  }
}



const handleWithdrawDora = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)

  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner()

  let address = await signer.getAddress()
  console.log("====")
  console.log(address)

  if (address !== userAddressInfo.address) {
    WrongAddress(userAddressInfo.address)
    setIsUserAddr(false)
  } else {
    setIsUserAddr(true)
    const contract = new Contract(claimContractAddress, claimAbi, provider);
    const withSigner = await contract.connect(signer);
    console.log(withSigner)
    await withSigner.withdraw();
    handleCloseWithdraw();
    WatingTx();
  }
}

    if (isLoading) {
      if (userAddressInfo === undefined) {
        return (
          <div className="tab-pane">
            <div className="card-container">
              <div className="card">
                <div className='card-detail'>
                  <div className="card-incentive-header">
                    Link your EVM wallet address to claim public good staking governance incentives
                  </div>
                  <div className="card-body">
                  <div className="card-description">The wallet address linked will be used to claim and stake the DORA tokens that you were rewarded for.{" "}<a className='private-info-link' href="https://research.dorahacks.io/2022/12/02/block-incentive-ecosystem-funding/" target="_blank">Learn more about public good staking<img src='/data/staking/chevron-right-yellow-sm.png'/></a></div>
                  </div>
                </div>
                  <div className="link-to-addresses-btn card-btn" onClick={() => handleOpen()}>
                    Link Address
                  </div>

                <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                <div className='modal-box-delete-btn' onClick={handleClose}>
                  <img src='/data/staking/icon-delete.png'/>
                </div>
                <div className='modal-box-container'>
                <div className='modal-box-head'>
                  Connect a wallet
                </div>
                {
                  switchLinkAccount==="" ?
                    <div className='modal-box-wallet-img-box'>
                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                    <p className='modal-box-wallet-name'>MetaMask</p>
                  </div>

                  : 
                  <div className='modal-box-wallet-img-box-switched'>
                  {/* <img className='modal-box-wallet-img' src='/data/staking/keplr-logo.png'/> */}
                  {
                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                  }
                  <div className='modal-box-switch-detail'>
                  <p className='modal-box-wallet-address-switched'>{switchLinkAccount.substring(0, 8) + '...' + switchLinkAccount.substring(switchLinkAccount.length-5,)}</p>
                  <div className='modal-box-wallet-network-parent'>
                  <p className='modal-box-wallet-network-switched'>{"Ethereum"} {"Mainnet"}</p>
                  </div>
                  </div>
                  <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/switch-icon.png'/>
                  </div>
                }
                <div className='modal-box-description'>
                  By confirming, you will be asked to sign a message in the wallet. No fee will be charged.
                </div>
                <div className='modal-box-btn-parent'>

                {
                  switchLinkAccount==="" ?
                  <div className='modal-box-confirm-btn' onClick={() => handleConfirmMetamask()}>Confirm to Link</div>
                  :
                  <div className='modal-box-confirm-btn switched' onClick={() => linkUserAddress()}>Confirm to Link</div>
                }
                  <div className='modal-box-cancel-btn' onClick={() => handleClose()}>Cancel</div>
                </div>
                </div>
              </Box>
            </Modal>
              </div>
              <div className="card">
              <div className='card-detail'>
                <div className="card-header">
                  Learn more about vcDORA & Dora Staking
                </div>
    
                <div className="card-body">
                    <div className="card-description">vcDORA, short for “voice credit DORA”, is the community governance credit generated by staking DORA tokens.{" "}<a className='private-info-link' href="https://dorahacks.io/blog/news/vcdora/" target="_blank">Learn more about vcDORA{" "}<img src='/data/staking/yellow-link-sm.png'/></a></div>
                </div>
                </div>
                <a href="https://dao.dorahacks.io/#/" target="_blank">
                <div className="pgs-link-btn card-btn">Dora Staking<img src='/data/staking/public-good-staking-btn-right-sm.png'/></div>
                </a>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="tab-pane">
          <div className='state-head-parent'>
            <div>
              <p className='state-head small-font'>EVM address linked:</p>
            </div>
            <div className='state-base big-font'> {userAddressInfo.address.substring(0, 6) + '...' + userAddressInfo.address.substring(userAddressInfo.address.length-5,)} </div>

    <div className='navbar-setting-img setting-logo-container' ref={props.dropdownContainerRef}>
          <img
            src='/data/staking/icon-setting.png'
            alt="User logo"
            onClick={handleSettingDropdownClick}
          />
          {props.settingDropdown && (
            <div className="setting-dropdown">
               <a href="https://dao.dorahacks.io/#/" target="_blank"><div className="setting-dropdown-item sample">Dora Staking <img src='/data/staking/public-good-staking-btn-right-sm.png'/></div></a>
              <a href="https://dorahacks.io/blog/news/vcdora/" target="_blank"><div className="setting-dropdown-item sample">About vcDORA <img src='/data/staking/public-good-staking-btn-right-sm.png'/></div></a>
              <div className="setting-dropdown-item unlink" onClick={() => handleUnlinkUserETHAddress(userAddressInfo.address)}>Unlink Address</div>
            </div>
          )}
        </div>
          </div>
            <div className='network-head-parent'>
            <div className="linked-address-head">
            Your governance incentives                
            </div>
            {
              accountDetail.current_claimable !== "0.0000"?
              <>
                <div className="claim-btn card-btn" onClick={handleOpenClaim}>Claim Incentive</div>
              
                <Modal
              open={openClaim}
              onClose={handleCloseClaim}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>

                <div className='modal-box-delete-btn' onClick={handleCloseClaim}>
                  <img src='/data/staking/icon-delete.png'/>
                </div>
                { confirmClaim === false ?
                                <div className='modal-box-claim-container'>
                                <div className='modal-box-head'>
                                  Claim your governance incentive
                                </div>
                                <div className='modal-box-body-description'>
                                You will claim and stake your DORA governance incentive.{" "}<a className='private-info-link' href="https://dorahacks.io/blog/news/vcdora/" target="_blank">Learn more about Dora Staking and vcDora.<img src='/data/staking/yellow-link-sm.png'/></a>
                                </div>
                                <div className='stake-amount-date-container'>
                                  <div>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                  <Select
                                    sx={style_select}
                                    // value={[stakeAmount, stakeMonth]}
                                    value={`${stakeAmount},${stakeMonth}`}
                                    // defaultValue={[stakeAmount, stakeMonth]}
                                    onChange={handleStakeChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                  >
                                    <MenuItem value={roundedUpNumberByMonth(accountDetail.current_claimable, 24)}>2 years {"("}104 weeks{")"}</MenuItem>
                                    <MenuItem value={roundedUpNumberByMonth(accountDetail.current_claimable, 12)}>1 years {"("}52 weeks{")"}</MenuItem>
                                    <MenuItem value={roundedUpNumberByMonth(accountDetail.current_claimable, 6)}>6 months {"("}26 weeks{")"}</MenuItem>
                                    <MenuItem value={roundedUpNumberByMonth(accountDetail.current_claimable, 3)}>3 months {"("}13 weeks{")"}</MenuItem>
                                  </Select>
                                </FormControl>
                                </div>
                                <div className='stake-amount'>
                                  {stakeAmount} DORA
                                </div>
                                </div>
                                <div className='modal-box-description'>
                                Select the lock time during which you want to stake your incentive. The DORA tokens that you can actually be rewarded with will be calculated automatically.
                                </div>
                                <div className='modal-box-description'>
                                  By staking for 4 years, the maximum amount you can claim is 20.5000 DORA.
                                </div>
                                <div className='modal-box-btn-parent'>
                
                                  <div className='modal-box-confirm-btn switched' onClick={() => handleConfirmClaim()}>Continue to Claim</div>
                                  <div className='modal-box-cancel-btn' onClick={() => handleCloseClaim()}>Cancel</div>
                                </div>
                                </div>
                                :
                                <div className='modal-box-withdraw-container'>
                                <div className='modal-box-head'>
                                  Connect your linked wallet
                                </div>
                                {
                                  switchLinkAccount==="" ?
                                    <div className='modal-box-wallet-img-box'>
                                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                                    <p className='modal-box-wallet-name'>MetaMask</p>
                                  </div>
                  
                                  : 
                                  <div className={isUserAddr === true?'modal-box-wallet-img-box-switched' : 'modal-box-wallet-img-box-switched wrong-user-eth-addr'}>
                                  {/* <img className='modal-box-wallet-img' src='/data/staking/keplr-logo.png'/> */}
                                  {
                                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                                  }
                                  <div className='modal-box-switch-detail'>
                                  <p className={isUserAddr===true ? 'modal-box-wallet-address-switched': 'modal-box-wallet-address-switched wrong-img'}>{switchLinkAccount.substring(0, 8) + '...' + switchLinkAccount.substring(switchLinkAccount.length-5,)}</p>
                                  <div className='modal-box-wallet-network-parent'>
                                  <p className={isUserAddr===true ?'modal-box-wallet-network-switched': 'modal-box-wallet-network-switched wrong-img'}>{"Ethereum"} {"Mainnet"}</p>
                                  </div>
                                  </div>
                                  {
                                    isUserAddr === true?
                                    <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/switch-icon.png'/>

                                    :
                                  <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/error-Cancel.png'/>

                                  }
                                  </div>
                                }
                                
                                <div className='stake-amount-date-container claim-height'>
                                  <div>
                                  <p className='claim-font big-font'>Claim</p>
                                </div>
                                <div className='claim-amount big-font'>
                                  {stakeAmount} DORA
                                </div>
                                
                                </div>

                                <div className='line-div'></div>
                                <div className='modal-box-description'>
                                  Please make sure you have sufficient ETH to pay the gas fee.
                                </div>
                                <div className='modal-box-btn-parent'>
                  
                                {
                                  switchLinkAccount==="" ?
                                  <div className='modal-box-confirm-btn' onClick={() => handleConfirmAddress()}>Claim & Stake</div>
                                  :
                                  <div className='modal-box-confirm-btn switched' onClick={() => handleClaimDora()}>Claim & Stake</div>
                                }
                                  <div className='modal-box-cancel-btn' onClick={() => handleCancelConfirmCLaim()}>Back</div>
                                </div>
                                </div>
                }

              </Box>
            </Modal>
              </>              
              :
              <>
              </>

            }

            </div>
            <div className="card-no-flex">
              <div className=''>
                <div className='state-card-child'>
                  <div className='state-big state-green'>{accountDetail.current_claimable}</div>
                  <div className='state-description'>Currently claimable* (DORA)</div>
                </div>
                <div className='state-card-child'>
                <div className='state-big'>{accountDetail.total_claimed}</div>
                  <div className='state-description'>Total claimed (DORA)</div>
                </div>
                <div className='state-card-child'>
                <div className='state-big'>{accountDetail.current_vcdora}</div>
                  <div className='state-description'>Current vcDORA</div>
                </div>
              </div>
            </div>

            { withdrawable !== "0.0000"?
                          <div className="card">
                          <div className='card-detail'>
                            <div className="card-header card-flex">
                              Withdrawable: <div className='state-green'>{`\u00A0`}{withdrawable} DORA</div>
                            </div>
                            <div className="card-body">
                              <div className="card-description">When the Dora Staking period expires, you can withdraw your incentive balance to your linked wallet address.</div>
                            </div>
                          </div>
                          <div className="link-to-addresses-btn card-btn" onClick={handleOpenWithdraw}>
                            Withdraw
                          </div>
                                        
                <Modal
              open={openWithdraw}
              onClose={handleCloseWithdraw}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>

                <div className='modal-box-delete-btn' onClick={handleCloseWithdraw}>
                  <img src='/data/staking/icon-delete.png'/>
                </div>
                                <div className='modal-box-withdraw-container'>
                                <div className='modal-box-head'>
                                  Connect your linked wallet
                                </div>
                                {
                                  switchLinkAccount === "" ?
                                    <div className='modal-box-wallet-img-box'>
                                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                                    <p className='modal-box-wallet-name'>MetaMask</p>
                                  </div>
                                  : 
                                  <div className={isUserAddr === true?'modal-box-wallet-img-box-switched' : 'modal-box-wallet-img-box-switched wrong-user-eth-addr'}>
                                    <img className='modal-box-wallet-img' src='/data/staking/metamask-logo.png'/>
                                  <div className='modal-box-switch-detail'>
                                  <p className={isUserAddr===true ? 'modal-box-wallet-address-switched': 'modal-box-wallet-address-switched wrong-img'}>{switchLinkAccount.substring(0, 8) + '...' + switchLinkAccount.substring(switchLinkAccount.length-5,)}</p>
                                  <div className='modal-box-wallet-network-parent'>
                                  <p className={isUserAddr===true ?'modal-box-wallet-network-switched': 'modal-box-wallet-network-switched wrong-img'}>{"Ethereum"} {"Mainnet"}</p>
                                  </div>
                                  </div>
                                  {
                                    isUserAddr === true?
                                    <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/switch-icon.png'/>

                                    :
                                  <img className='modal-box-switch-Cancel-btn' onClick={handleCancelSwitch} src='/data/staking/error-Cancel.png'/>

                                  }
                                  </div>
                                }
                                
                                <div className='withdraw-amount-container'>
                                  <div className='claim-font big-font'>
                                  Withdrawal
                                </div>
                                <div className='withdraw-amount big-font'>
                                  {withdrawable} DORA
                                </div>
                                
                                </div>

                                <div className='line-div'></div>
                                <div className='modal-box-description'>
                                  Please make sure you have sufficient ETH to pay the gas fee.
                                </div>
                                <div className='modal-box-btn-parent'>
                  
                                {
                                  switchLinkAccount==="" ?
                                  <> 
                                   <div className='modal-box-confirm-btn' onClick={() => handleConfirmAddress()}>Withdraw</div>
                                   <div className='modal-box-cancel-btn' onClick={() => handleCloseWithdraw()}>Back</div>
                                  </>
                                  
                                  :
                                  <> 
                                  <div className='modal-box-confirm-btn switched' onClick={() => handleWithdrawDora()}>Withdraw</div>
                                   <div className='modal-box-cancel-btn' onClick={() => handleCancelSwitch()}>Back</div>
                                  </>
                                }
                                </div>
                                </div>

              </Box>
            </Modal>
            
                        </div>

              :
              <>
              </>
            }


            {
              accountDetail.current_claimable === '0.0000' && accountDetail.total_claimed === '0.0000' ?
              <div className="card">
              <div className='card-detail'>
              <div className="card-header">
                Get incentives from public good staking
              </div>

              <div className="card-body">
                {/* <div className="card-description">The validator you staked to started on 11 Nov 2022, and has been running for 364 days. You can find more details of the validator on this explorer.</div> */}
                <div className="card-description">Public good staking is committed to grow a new generation of blockchain networks. By funding ecosystem ventures with block incentives, you will be able to decentralize blockchain governance and get rewarded with DORA tokens.</div>
            </div>
            </div>

            <a href="https://research.dorahacks.io/2022/12/02/block-incentive-ecosystem-funding/" target="_blank">
                <div className="pgs-link-btn card-btn">Public Good Staking{" "}<img src='/data/staking/public-good-staking-btn-right-sm.png'/></div>
                </a>
            </div>
            :
              <>
          </>
            }

        { claimHistory.map((claim_history, index) => {
            return (
              <div>
              { 
                index === 0 ?
                <>
                <div className='incentives-history-tips'>
                * The incentives will be settled and distributed on the 15th of each month.
                </div>
                <div className='linked-address-head'>
                  Claim History
                </div>
                <thead className='card-form-head card-detail-form-head'>
                    <tr className=''>
                      <th className="chain-name-form-head">DISTRIBUTION DATE</th>
                      {/* <th className="chain-name-form-head">STATUS</th> */}
                      {/* <th className="chain-name-form-head">CLAMABLE</th> */}
                      <th className="chain-name-form-head">CLAIMED</th>
                    </tr>
                  </thead>
                </>

                : null
              }
            <div className="card chain-form-card">
              <tbody className=''>
                <tr className="">
                <th className="chain-name">{claim_history.date}</th>
                {/* <th className={claim_history.status==='Claimed'? "claimed-status chain-name" : claim_history.status==='Pending'? "pending-status chain-name": "expired-status chain-name"}>{claim_history.status}</th> */}
                {/* <th className="chain-name">{claim_history.claimable} DORA</th> */}
                <th className="chain-name">{claim_history.claim_amount} DORA</th>
                </tr>
              </tbody>
              <a href={claim_history.tx_url} target="_blank"><div className="card-btn detail-btn"><div><img src='/data/staking/chevron-right-sm.png'/></div></div></a>

            </div>
              </div>
            )

          })}
          </div>
        )
      }
    } else {
      return (
      <div className='process-container'>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>)
    }
}

export default IncentivesTab;
