// import SideMenu from '../components/sideMenu'
import LogOutCard from '../components/login/logout'
import '../styles/wallet.scss'

function LogOut () {
  return (
    <div>
        <LogOutCard></LogOutCard>
    </div>
  )
}

export default LogOut
