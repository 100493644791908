// import SideMenu from '../components/sideMenu'
import Code2FaCard from '../components/login/code_2fa'
import '../styles/wallet.scss'

function Code2Fa () {
  return (
    <div>
        <Code2FaCard></Code2FaCard>
    </div>
  )
}

export default Code2Fa
