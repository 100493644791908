// import SideMenu from '../components/sideMenu'
import VerifyEmailCard from '../components/login/verify_email'
import '../styles/wallet.scss'

function VerifyEmail () {
  return (
    <div>
        <VerifyEmailCard></VerifyEmailCard>
    </div>
  )
}

export default VerifyEmail
