// import SideMenu from '../components/sideMenu'
import ActiveAccountCard from '../components/login/active_account'
import '../styles/wallet.scss'

function ActiveAccount () {
  return (
    <div>
        <ActiveAccountCard></ActiveAccountCard>
    </div>
  )
}

export default ActiveAccount
