import React, { useState, useRef, useEffect } from 'react';
// import './TabSwitcher.css';
import '../../styles/TabSwitcher.scss';
import StatsTab from './pgs_stats';
import AddressesTab from './pgs_address';
import IncentivesTab from './pgs_incentives';
import FooterTab from './pgs_footer';
import { useNavigate, Link } from 'react-router-dom';

import axios from 'axios';


function TabSwitcher() {
  const [activeTab, setActiveTab] = useState('Stats');
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    handleUserInfo();
  },[]);

  const handleUserInfo = async()  => {
    try {
        const result = await axios.get("/api/userinfo/", {
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
            "dorafactory-token": localStorage.getItem('token'),
        },});

        if(result.data['status'] === "successed"){
            if (result.data['data']['is_active'] === false) {
              navigate("/verify_email")
            } else if (result.data['data']['otp_enabled'] === null) {
                navigate("/enable")
            } else if (result.data['data']['otp_enabled'] === true) {
                if (result.data['data']['otp_2fa_login_required'] !== true) {
                    navigate("/verify_code")
              }
          }
        } else {
            navigate("/login")
        }
        setIsLoading(true)
    } catch {
        navigate("/login")
    }
}



const [showDropdown, setShowDropdown] = useState(false);
const [settingDropdown, setSettingDropdown] = useState(false);
const dropdownContainerRef = useRef(null);
const settingDropdownContainerRef = useRef(null);

const handleDropdownClick = () => {
  setShowDropdown(!showDropdown);
};

const handleSettingDropdownClick = () => {
  setSettingDropdown(!settingDropdown);
};

const handleLogout = () => {
  navigate("/logout");
};

const handleHomePage = () => {
  navigate("/home");
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
      setShowDropdown(false);
      // setSettingDropdown(false);
    }
  };
  const handleSettingClickOutside = (event) => {
    if (settingDropdownContainerRef.current && !settingDropdownContainerRef.current.contains(event.target)) {
      setSettingDropdown(false);
    }
  };
  document.addEventListener('click', handleClickOutside);
  document.addEventListener('click', handleSettingClickOutside);
  return () => {
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('click', handleSettingClickOutside);
  };
}, []);


if (isLoading) {
  return (
    <div className="tab-switcher-container">
        <nav className="head-tab-container">
          <div className='navbar-brand-img'>
          <div className='navbar-brand-img' onClick={handleHomePage}>
           <img src='/data/staking/navbar-brand.png' />
          </div>

        </div>
        <div className="page-tab-container">
          <a className="page-tab-item pgs-head-active">Public Good Staking</a>
          {/* <a className="api-service-tab-item">Advanced APIs{`\u00A0`}<p className='coming-coon-item'>Coming Soon !</p></a> */}
        </div>
        <div className='navbar-user-logo-img logo-container' ref={dropdownContainerRef}>
          <img
            src='/data/staking/navbar-account.png'
            alt="User logo"
            onClick={handleDropdownClick}
          />
          {showDropdown && (
            <div className="dropdown">
              <div className="dropdown-item logout" onClick={handleLogout}>Sign Out</div>
            </div>
          )}
        </div>
      </nav>


        <div className='tab-switcher-card'>
        <div className="tab-nav">




          <div
            className={`tab-item ${activeTab === 'Stats' ? 'active' : ''}`}
            onClick={() => handleTabClick('Stats')}
          >
                    {
          activeTab === 'Stats'?
          <img src='/data/staking/icon-stats-switch.png'/>
          :
          <img src='/data/staking/icon-stats.png'/>
        }
            <div className='tab-item-name'>Stats</div>
          </div>

          <div
            className={`tab-item ${activeTab === 'Addresses' ? 'active' : ''}`}
            onClick={() => handleTabClick('Addresses')}
          >
                      {
          activeTab === 'Addresses' ?
          <img src='/data/staking/icon-addresses-switch.png'/>
          :
          <img src='/data/staking/icon-addresses.png'/>
        }
          <div className='tab-item-name'>Addresses</div>
          </div>


          <div
            className={`tab-item ${activeTab === 'Incentives' ? 'active' : ''}`}
            onClick={() => handleTabClick('Incentives')}
          >
                      {
          activeTab === 'Incentives' ?
          <img src='/data/staking/icon-incentives-switch.svg'/>
          :
          <img src='/data/staking/icon-incentives.png'/>
        }
          <div className='tab-item-name'>Incentives</div>
          </div>
        </div>
        <div className="tab-content">
          {/* {activeTab === 'Stats' ? <StatsTab /> : <AddressesTab />}
           */}
               <div>
      {activeTab === 'Stats' && <StatsTab setActiveTab={setActiveTab} />}
      {activeTab === 'Addresses' && <AddressesTab setActiveTab={setActiveTab} />}
      {activeTab === 'Incentives' && <IncentivesTab handleSettingDropdownClick={handleSettingDropdownClick} settingDropdown={settingDropdown} dropdownContainerRef={settingDropdownContainerRef}/>}
    </div>
        </div>
      </div>
      <FooterTab/>
    </div>
    );
  }
}

export default TabSwitcher;
