// import SideMenu from '../components/sideMenu'
import LoginUserCard from '../components/login/login'
import '../styles/wallet.scss'

function Login () {
  return (
    <div>
        <LoginUserCard></LoginUserCard>
    </div>
  )
}

export default Login
