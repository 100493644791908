// import SideMenu from '../components/sideMenu'
import Enable2FaCard from '../components/login/enable_2fa'
import '../styles/wallet.scss'

function Enable2Fa () {
  return (
    <div>
        <Enable2FaCard></Enable2FaCard>
    </div>
  )
}

export default Enable2Fa
