import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';

const LogOutCard = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        handleLogout();
    },[]);


    const handleLogout = async()  => {
        const result = await axios(
            {
                method: "patch",
                baseURL: "https://app-dev.dorafactory.org",
                url: '/api/logout/',
                headers: {
                    'Content-Type': 'application/json',
                    "dorafactory-token": localStorage.getItem('token'),
                },
            });
        console.log(result.data)
        if (result.data['status'] === "successed" || result.data['code'] === "error") {
            navigate("/login")
        }
    }


    return(
        <>
        </>
    )
}


export default LogOutCard;