// import SideMenu from '../components/sideMenu'
import SignInByCodeCard from '../components/login/signin_with_code'
import '../styles/wallet.scss'

function SignInByCode () {
  return (
    <div>
        <SignInByCodeCard></SignInByCodeCard>
    </div>
  )
}

export default SignInByCode
