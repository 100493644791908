
import React, { useState, useEffect } from 'react';
// import './TabSwitcher.css';
import '../../styles/TabSwitcher.scss';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';

function StatsTab(props) {
    const [linkedAddresses, setLinkedAddresses] = useState([]);
    const [switchNetwork, setSwitchNetwork] = useState("");
    const [switchAddress, setSwitchAddress] = useState("");
    const [accountDetail, setAccountDetail] = useState({});
    const [networkDetail, setNetworkDetail] = useState({});
    const [pgsDetail, setPGSDetail] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    
    useEffect(() => {
      handleUserAddressesInfo();
    },[]);

    
    const handleUserAddressesInfo = async () => {
      try {
        const result = await axios.get(`/api/list_address/`, {
          baseURL: "https://app-dev.dorafactory.org",
          headers: {
            "dorafactory-token": localStorage.getItem('token'),
          },
        });

        if (result.data.code === 'error') {
          navigate("/login")
        }
        const promises = result.data.data.flatMap(async (res) => {
          try {
            const chainStatusResponse = await axios.get(`https://pgs-api-dev.dorafactory.org/validators/?network=${res.network_name.toLowerCase()}`);
            const chainStatus = chainStatusResponse.data.data;
            let precision = chainStatusResponse.data.data.precision;
            let node_url = chainStatusResponse.data.data.node_url;
            let symbol = chainStatusResponse.data.data.symbol;

            const stakeResult = await axios.get(
             `https://pgs-api-dev.dorafactory.org/user_stake/?address=${res.address}`
            );  
                      
            
            const updatedStakeResult = await Promise.all(stakeResult.data.data.map(async (stakeRes) => {
              let stake = "0.00";
              let reward = "0.00";
              console.log("-------")
              
              if (stakeRes.stake !== "") {
                const resStake = stakeRes.stake;
                const resReward = stakeRes.reward;
                stake = parseFloat(resStake.slice(0, -1 * precision) + '.' + resStake.slice(-1 * precision)).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });
                reward = parseFloat(resReward.slice(0, -1 * precision) + '.' + resReward.slice(-1 * precision)).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });
                
              }
              return {
                address: res.address,
                network: stakeRes.network,
                is_pgs: stakeRes.is_pgs,
                stake,
                reward,
              };
            }));
            
            return updatedStakeResult.map(stakeRes => ({
              ...res,
              ...chainStatus,
              ...stakeRes,
              chain_status: chainStatus.status ? "active" : "inactive",
            }));
          } catch (error) {
            console.error(`Failed to fetch data for address ${res.address}: ${error.message}`);
            return [];
          }
        });
        const resolvedPromises = await Promise.all(promises);
        const flattenedPromises = resolvedPromises.flat();

        setLinkedAddresses(flattenedPromises);
        setIsLoading(true);
      } catch (error) {
        console.error(`Failed to fetch list of addresses: ${error.message}`);
        navigate("/login")
      }
    };
  
    useEffect(() => {
      handleUserNetworkInfo();
    },[switchNetwork,pgsDetail,]);
    
    const handleUserNetworkInfo = async()  => {
      if (switchNetwork !== '') {
        linkedAddresses.map(async (linkAddress) => {
          if (linkAddress.network_name === switchNetwork && linkAddress.is_pgs === pgsDetail) {
            setAccountDetail(linkAddress)
          }
        })
      }
  }

    const handleSwitchNetwork = (network, address, is_pgs_data) => {
      setSwitchNetwork(network)
      setSwitchAddress(address)
      setPGSDetail(is_pgs_data)
    }

    const handleSwitchAddress = (event) => {
      const selectedAddress = event.target.value.split(",");

      console.log(selectedAddress)
      setSwitchNetwork(selectedAddress[0])
      setSwitchAddress(selectedAddress[1])
      setPGSDetail(selectedAddress[2])
    }

    const handleLinkAddresses = () => {
      props.setActiveTab('Addresses');
    };
    if (isLoading) {
      if (linkedAddresses.length === 0) {
        return (
          <div className="tab-pane">
            <div className="card-container">
              <div className="card">
                <div className='card-detail'>
                  <div className="card-header">
                    Link Your Wallet Address to Get Started
                  </div>
                  <div className="card-body">
                  <div className="card-description">You will be able to access your current status of public good staking from all linked addresses.{" "}<a className='private-info-link' href="https://research.dorahacks.io/2022/12/02/block-incentive-ecosystem-funding/" target="_blank">Learn more about public good staking<img src='/data/staking/chevron-right-yellow-sm.png'/></a></div>
                  </div>
                </div>
                  <div className="link-to-addresses-btn card-btn" onClick={() => handleLinkAddresses()}>
                    Link Address
                  </div>
              </div>
              <div className="card">
              <div className='card-detail'>
                <div className="card-header">
                  About Public Good Staking
                </div>
    
                <div className="card-body">
                    <div className="card-description">Public good staking is committed to grow a new generation of blockchain networks. By funding ecosystem ventures with block incentives, you will be able to decentralize blockchain governance with your voice.</div>
                </div>
                </div>
                <a href="https://research.dorahacks.io/2022/12/02/block-incentive-ecosystem-funding/" target="_blank">
                <div className="pgs-link-btn card-btn">Public Good Staking{" "}<img src='/data/staking/public-good-staking-btn-right-sm.png'/></div>
                </a>
              </div>
            </div>
          </div>
        );
    
    } else {
      if (switchNetwork !== "") {
        return (
          <div className="tab-pane">
            <div className='state-head-parent'>
          <div>
            <p className='state-head big-font' onClick={() => handleSwitchNetwork('', '', '')}>Stats</p>
          </div>
          <div className='state-base'> / </div>

      <select onChange={(event) => handleSwitchAddress(event)} value={[switchNetwork, switchAddress, pgsDetail]} className='select-address'>
        {linkedAddresses.map((linked_address, index) => {
          return (
            <option key={index} value={[linked_address.network_name, linked_address.address, linked_address.is_pgs]}>
              {linked_address.address}{linked_address.is_pgs==="PGS"?"-PGS":""}
            </option>
          );
        })}
      </select>
    </div>
            <div className='network-head-parent'>
            <img className="staking-ecosys-logo" src={`/data/staking/logo-${switchNetwork.toLowerCase()}.png`} alt={switchNetwork.toLowerCase()}/>

            <div className='switch-network-head'>{switchNetwork}{pgsDetail==="PGS"?"-PGS":""}</div>
            <div className={accountDetail.chain_status==='inactive'? "network-status inactive-status": "network-status active-status"}>{accountDetail.chain_status}</div>
            </div>
            <div className="card-no-flex">

              <div className=''>
                <div className='state-card-child'>
                  <div className='state-big state-green'>{accountDetail.reward===''?0:accountDetail.reward}</div>
                  <div className='state-description'>Your rewards ({accountDetail.symbol})</div>
                </div>
                <div className='state-card-child'>
                <div className='state-big'>{accountDetail.stake===''?0:accountDetail.stake}</div>
                  <div className='state-description'>Your staked ({accountDetail.symbol})</div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className='card-detail'>
              <div className="card-header">
                About this validator
              </div>

              <div className="card-body">
                {/* <div className="card-description">The validator you staked to started on 11 Nov 2022, and has been running for 364 days. You can find more details of the validator on this explorer.</div> */}
                <div className="card-description">You can find more details of the validator on this explorer.</div>
                <div className="card-description operator-bottom-name">The validator is operated by{" "}<a className='private-info-link' href="https://dorafactory.org/">Dora Factory.<img src='/data/staking/yellow-link-sm.png'/></a></div>
            </div>
            </div>
            <a href={accountDetail.node_url} target='_blank'><div className="link-wallet-btn card-btn">View on explorer</div></a>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="tab-pane">
            <div className="card-container">
              { linkedAddresses.map((linked_address, index) => {
                return (
                  <div>
                  { 
                    index === 0 ?
                    <>
                    <div className='linked-address-head'>
                      Stats of your validators
                    </div>
                    <thead className='card-form-head card-detail-form-head'>
                      <tr className=''>
                        <th className="chain-name-form-head">WALLET ADDRESS</th>
                        <th className="chain-name-form-head">STATUS</th>
                        <th className="chain-name-form-head">BLOCKCHAIN</th>
                        <th className="chain-name-form-head">
                          <Tooltip title="This data will be updated every 30 minutes." enterDelay={500} leaveDelay={200} placement="top">
                            <p>YOUR STAKE</p>
                          </Tooltip>
                        </th>
                        <th className="chain-name-form-head">
                        <Tooltip title="This data will be updated every 30 minutes." enterDelay={500} leaveDelay={200} placement="top">
                          <p>YOUR REWAREDS</p>
                        </Tooltip>
                        </th>
                      </tr>
                    </thead>
                    </>
          
                    : null
                  }
                <div className="card chain-form-card">
                  <tbody className=''>
                    <tr className="">
                    <th className="chain-name big-font">{linked_address.address.substring(0, 8) + '...' + linked_address.address.substring(linked_address.address.length-5,)}</th>
                        <th className={linked_address.chain_status==='inactive'? "inactive-status chain-name": "active-status chain-name"}>{linked_address.chain_status}</th>
                        <th className="chain-name">{linked_address.network_name}{linked_address.is_pgs==="PGS"?"-PGS":""}</th>
                        <th className="chain-name">{linked_address.stake} {linked_address.symbol}</th>
                        <th className="chain-name">{linked_address.reward} {linked_address.symbol}</th>
                    </tr>
                  </tbody>
                  <div className="card-btn detail-btn"><div  onClick={() => handleSwitchNetwork(linked_address.network_name, linked_address.address, linked_address.is_pgs)}><img src='/data/staking/chevron-right-sm.png'/></div></div>

                </div>
                  </div>
                )

              })}
              </div>
              <div className="card">
                <div className='card-detail'>
                  <div className="card-header">
                    About Public Good Staking
                  </div>
      
                  <div className="card-body">
                      <div className="card-description">Public good staking is committed to grow a new generation of blockchain networks. By funding ecosystem ventures with block incentives, you will be able to decentralize blockchain governance with your voice.</div>
                  </div>
                  </div>
                  <a href="https://research.dorahacks.io/2022/12/02/block-incentive-ecosystem-funding/" target="_blank">
                  <div className="card-btn pgs-link-btn">Public Good Staking{" "}<img src='/data/staking/public-good-staking-btn-right-sm.png'/></div>
                  </a>
                </div>
          </div>
        )
        }
      }
  } 
}

export default StatsTab;
