import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';

import '../../styles/login.scss';
import axios from 'axios';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import SignUpSvg from '../../resources/signup.svg';
import { message } from 'antd';
import '../../styles/antd-message.scss';

message.config({
    top:100,
    duration:2
})

const VerifySuccess = () => {
    message.success('Verify Successed!', 1);
};

const VerifyFailed = () => {
    message.error('Verify Failed!', 1);
};

const InputError = (msg) => {
    message.error(`${msg}`, 3);
  };

const Code2FaCard = () => {
    const [token, setToken] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [emailState, setEmailState] = useState();

    const handleTokenChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setToken(value);
    }

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        handleUserInfo();
    },[]);

    const handleUserInfo = async()  => {
        try {
            setEmailState(localStorage.getItem('email'));
            const result = await axios.get("/api/userinfo/", {headers: {
                "dorafactory-token": localStorage.getItem('token'),
            },});

            if(result.data['status'] === "successed"){
                setUserInfo(JSON.stringify(result.data['data']))
                if (result.data['data']['is_active'] === false) {
                    navigate("/verify_email")
                } else if (result.data['data']['otp_enabled'] === null) {
                    navigate("/enable")
                } else if (result.data['data']['otp_enabled'] === true) {
                    if (result.data['data']['otp_2fa_login_required'] === true) {
                        navigate("/home")
                    } else {
                        navigate("/verify_code")
                    }
                } else if (result.data['data']['otp_enabled'] === false) {
                    navigate("/home")
                }
            } else {
                navigate("/login")
            }
            setIsLoading(true)
        } catch {
            navigate("/login")
        }
    }
    

    const handleKeyDownVerify2FACode = (event) => {
        if (event.keyCode === 13) {
            handleVerify2FACode()
        }
    }

    const handleVerify2FACode = async () => {
        if (token === '') {
            InputError("Token cannot be empty!")
            return
        }

        const data = {
            "token": token,
        }
        const result = await axios({
            method: "patch",
            url: `/api/otp/validate/`,
            headers: {
              'Content-Type': 'application/json',
              "dorafactory-token": localStorage.getItem('token'),
            },
            data
        });
        if (result.data['status'] === 'success') {
            VerifySuccess()
            navigate("/home")
        } else {
            VerifyFailed()
        }
    }

    const handleSignup = () => {
        navigate("/signup")
    }
    if (isLoading) {
        return(
            <div className="login-card signup-card">
                <img src={SignUpSvg}/>
                <div className='signup-title'>Enter 2FA code</div>
                <div className="code-2fa-body-1">Enter the code for {emailState} found in your authenticator app.</div>
            <FormControl sx={{ m: 0, width: '360px' }} variant="outlined" size="small">
            <OutlinedInput
                id="outlined-adornment-weight"
                className="email-value"
                value={token}
                onKeyDown={(e) => handleKeyDownVerify2FACode(e)}
                onChange={(e) => handleTokenChange(e)}
                aria-describedby="outlined-weight-helper-text"
                placeholder="Enter the 6-digit code"
                inputProps={{
                'aria-label': 'weight',
                }}
            />
            </FormControl>
                <div className={"login-btn-base login-btn-background login-btn-choose"} onClick={handleVerify2FACode}>
                    <div className={"check-signup-font"} >
                        Verify
                    </div>
                </div>
                
                {/* <div className="code-2fa-body-2">Lost access to your authenticator?<a className='private-info-link-bottom'>Enter a backup code.</a></div> */}

            </div>
        )
    }
}

export default Code2FaCard;